<template>

  <div class="row">
    <div class="col-12">
      <ul 
        v-show="categorys.length != 0"
        class="list-group">
        <li 
          class="list-group-item d-flex justify-content-between align-items-center"
          v-for="(cat, i) in categorys"
          :key="i"
        >
          <span class="text-secondary">
            <font-awesome-icon 
              icon="industry" 
              class="pe-1"
            />
            {{ cat.nombre }}
          </span>
          <div class="dropdown dropdown-custom d-flex justify-content-center">
            <button 
              class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
              type="button" 
              data-bs-toggle="dropdown">
              <font-awesome-icon icon="ellipsis-vertical"/>
            </button>
            <ul class="dropdown-menu">
              <li>
                <a 
                  @click="editCompany(cat.nombre, cat.id_categoria)"
                  data-bs-toggle="modal" 
                  data-bs-target="#modal-organigrama-empresa"
                  class="dropdown-item" 
                  href="javascript:">
                  <font-awesome-icon class="pe-2 color-brown" icon="pen"/>
                  Editar
                </a>
              </li>
              <li>
                <a 
                  @click="deleteCompany(cat.nombre, cat.id_categoria)"
                  class="dropdown-item" 
                  href="javascript:">
                  <font-awesome-icon class="pe-2 color-red" icon="trash-alt"/>
                  Eliminar
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>

      <div
        v-show="categorys.length == 0"
        class="nodata-section__wrapper"
      >
        <img
          class="nodata-section__img"
          src="@/assets/img/utilities/question_face.svg"
          alt=""
        />
        <p class="nodata-section__text">Aún no se han registrado empresas</p>
        <div class="nodata-section__button-wrapper">
          <button class="nodata-section__button">+ Crear empresa</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
   props: {
      categorys: {
         type: Array,
      },
   },
  data() {
    return {
      option: "",
    };
  },
  methods: {
    openOptions(id_) {
      if (this.option == id_) {
        this.outOption;
      } else {
        setTimeout(() => {
          this.option = id_;
        }, 10);
      }
    },
    outOption() {
      if (this.option != "") {
        this.option = "";
      }
    },
    editCompany(name_, id) {
      this.option = "";
      this.$emit("editCompany", name_, id);
    },
    deleteCompany(name_, id) {
      //console.log("eliminar");
      this.option = "";
      this.$emit("deleteCompany", name_, id);
    },
  },
};
</script>
