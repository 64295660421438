<template>

<div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title text-secondary">
        <font-awesome-icon 
          icon="circle-user" 
          class="color-secondary me-1"
        />
        <span v-if="!editName">Nueva empresa</span>
        <span v-if="editName">Editar empresa</span>
      </h5>
      <button 
        type="button" 
        class="modal-custom-close"
        data-bs-dismiss="modal" 
        aria-label="Close">
        <font-awesome-icon icon="xmark"/>
      </button>
    </div>
    <div class="modal-body">

      <div class="row">
        <div class="col-12">
          <label class="text-secondary">Nombre empresa</label>
          <input 
            type="text" 
            class="form-control input-custom" 
            id="inputCategory"
            v-model="inputCategory"
            maxlength="200"
          />
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button 
        type="button" 
        ref="closeModal"
        @click="closeModal()"
        class="btn btn-custom-color-white rounded-pill mw-100" 
        data-bs-dismiss="modal">
        <i class="fa-solid fa-angle-left pe-1"></i>
        Volver
      </button>
      <button 
        v-if="editName"
        @click="addCompany()"
        type="button" 
        class="btn btn-custom-color-blue rounded-pill mw-100">
        Guardar
      </button>
      <button 
        v-if="!editName"
        @click="addCompany()"
        type="button" 
        class="btn btn-custom-color-blue rounded-pill mw-100">
        Agregar
      </button>
    </div>
  </div>

</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    categoryName: {
      type: String,
    },
    id_category: {
      type: String
    },
    editName: {
      type: Boolean,
    },
  },
  data() {
    return {
      inputCategory: "",
      id_modulo: '17',
    };
  },
  mounted() {
    this.getValues();
  },
  methods: {
    ...mapActions("organigramaModule", ["crearEmpresaCategoria", "editarEmpresaCategoria"]),
    closeModal() {
      this.$emit("close");

    },
    async addCompany() {
      // console.log("nombre agregado: ", this.categoryName);
      // console.log("nombre agregado: ", this.inputCategory);
      if(this.id_category!=""){
        let data_ = {
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        id_modificador: this.$ls.get("user").id_usuario,
        nombre: this.inputCategory,
        formato_archivo: "",
        archivo: "",
        id_modulo: this.id_modulo,
        id_categoria: this.id_category 
        }
        //console.log("editando: ", "id_categoria: ", this.id_category, " data_: ", data_);
        await this.editarEmpresaCategoria(data_).then(res=>{
          if(res){
            this.$toast.open({
            message: "Tu empresa ha sido editada correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
          this.$refs.closeModal.click();
          }
          else {
            this.$toast.open({
              message: "Ocurrio un problema al intentar editar una empresa",
              type: "error",
              duration: 0,
              position: "top-right",
            });
          }
        });
      }
      else{
        let data_ = {
          id_empresa: this.$ls.get("user").empresa[0].id_empresa,
          id_creador: this.$ls.get("user").id_usuario,
          nombre: this.inputCategory,
          formato_archivo: "",
          archivo: "",
          id_modulo: this.id_modulo
        }
        console.log("Creando: ", data_);
        await this.crearEmpresaCategoria(data_).then(res=>{
          if(res){
            this.$toast.open({
            message: "Tu empresa ha sido creada correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
          this.$refs.closeModal.click();
          }
          else {
            this.$toast.open({
              message: "Ocurrio un problema al intentar crear una empresa",
              type: "error",
              duration: 0,
              position: "top-right",
            });
          }
        });
      }
      
      this.$emit("add-company");
    },
    getValues() {
      if (this.editName === true) {
        document
          .getElementById("inputCategory")
          .setAttribute("value", this.categoryName);
      }
    },
  },
};
</script>
