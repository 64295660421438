<template>

  <section id="organigrama-empresas">
    <div class="row mt-3">
      <div class="col-12">
        <h3 class="section-head-title ps-0 mb-3">
          <font-awesome-icon 
            icon="industry" 
            class="color-secondary pe-1"
          />
          Revisa o crea empresas para asociar a los organigramas.
        </h3>

        <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
          <div class="card-body d-flex flex-column mh-700">
            <div class="row d-flex align-items-center my-3">
              <div class="col-12 col-lg-9">
                <h5 class="font-main text-secondary">
                  Lista de empresas creadas
                </h5>
              </div>
              <div class="col-12 col-lg-3 d-flex justify-content-end">
                <button 
                  type="button"
                  data-bs-toggle="modal" 
                  data-bs-target="#modal-organigrama-empresa"
                  @click="add_modal = true"
                  class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
                  <font-awesome-icon 
                    icon="plus" 
                    class="pe-2"
                  />
                  Crear empresa
                </button>
              </div>   
            </div>
            <spinner v-show="show_spinner == true"/>
            <OrganigramaEmpresasTable
              :categorys="categoriasEmpresas"
              @deleteCompany="deleteCompany"
              @editCompany="editCompany"
            />

          </div>
        </div>

      </div>
    </div>

    <!-- Modal -->
    <div 
      class="modal modal-custom fade" 
      id="modal-organigrama-empresa" 
      tabindex="-1" 
    >
      <div class="modal-dialog">
        <AddEmpresa
          v-if="add_modal == true"
          :categoryName="name_category"
          :id_category="id_category"
          :editName="editName"
          @close="closeModal"
          @add-company="addCompany"
        />
      </div>
    </div>

    <Question
      v-if="open_question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />
  </section>

</template>

<script>
import { mapState, mapActions } from "vuex";
import OrganigramaEmpresasTable from "./OrganigramaEmpresasTable.vue";
import AddEmpresa from "./AddEmpresa.vue";
import Spinner from "../Spinner.vue";
import Question from "../Modales/Question.vue";
export default {
  components: { OrganigramaEmpresasTable, AddEmpresa, Spinner, Question },
  data() {
    return {
      add_modal: false,
      show_spinner: true,
      open_question_modal: false,
      question_modal_msg: "",
      pagination: {
        actual_page: 1,
        page_count: 0,
        page_range: 3,
        click_handler: this.changePage,
        prev_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class: "pagination-item",
      },
      name_category: "",
      editName: false,
      id_company: this.$ls.get("user").empresa[0].id_empresa,
      id_modulo: '17',
      id_category: ""
    };
  },
  computed: {
    ...mapState("organigramaModule", ["categoriasEmpresas"]),
  },
  created(){
    this.getCategoriaEmpresa();
  },
  methods: {
    ...mapActions("organigramaModule", ["getCategoriaForEmpresa", "deleteEmpresaCategoria"]),
    async getCategoriaEmpresa(){
      let data_ = {
        id_empresa: this.id_company,
        id_modulo: this.id_modulo
      }
      console.log("data_ get categorias ", data_);
      await this.getCategoriaForEmpresa(data_);
      this.show_spinner = false;
      
    },
    closeModal() {
      this.add_modal = false;
      this.editName = false;
    },
    addCompany() {    
      this.add_modal = false;
      if (this.editName == true) {
        this.editName = false;
      } 
    },
    cancelQuestion() {
      this.open_question_modal = false;
    },
    async acceptQuestion() {
      this.show_spinner = true;
      this.open_question_modal = false;
      let data_ = {
        id_categoria: this.id_category,
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        id_eliminador: this.$ls.get("user").id_usuario,
        id_modulo: this.id_modulo
      }
      await this.deleteEmpresaCategoria(data_).then(res=>{
        if(res){
            this.$toast.open({
            message: "Tu empresa ha sido eliminada correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
        }
        else{
          this.$toast.open({
              message: "Ocurrio un problema al intentar eliminar la empresa",
              type: "error",
              duration: 0,
              position: "top-right",
            });
        }
        this.show_spinner = false;
      });
    },
    editCompany(name_, id) {
      //console.log("acá para editar: id: ", id.toString());
      this.add_modal = true;
      this.name_category = name_;
      this.id_category = id.toString();
      this.editName = true;
    },
    deleteCompany(name_, id) {
      this.open_question_modal = true;
      this.question_modal_msg = "¿Estás seguro de eliminar ésta empresa?";
      //console.log("eliminando todo: ID: ", id);
      this.name_category = name_;
      this.id_category = id;
    },
  },
};
</script>
